import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
    
    const targetDate = new Date('2024-12-31T00:00:00');
    const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const { t } = useTranslation();

    useEffect(() => {
        const calculateTimeRemaining = () => {
            const now = new Date();
            const totalSeconds = Math.floor((targetDate - now) / 1000);

            if (totalSeconds < 0) {
                setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                clearInterval(intervalId);
                return;
            }

            const days = Math.floor(totalSeconds / 86400);
            const hours = Math.floor((totalSeconds % 86400) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            setTimeRemaining({ days, hours, minutes, seconds });
        };

        const intervalId = setInterval(calculateTimeRemaining, 1000);
        calculateTimeRemaining();

        return () => clearInterval(intervalId);
    }, [targetDate]);

    return (
        <div className='container py-5'>
            <Row className='justify-content-between py-3'>
                <Col xl="6" className='my-3'>
                    <h1 className='fw-bold text-dark' style={{ fontSize: "350%" }}>
                        {t('coming_soon_title')}
                    </h1>
                    <p className='fs-3 fw-light my-4'>
                        {t('coming_soon_caption')}
                    </p>

                    <div className='d-flex justify-content-between align-items-center'>
                        {['days', 'hours', 'minutes', 'seconds'].map((unit, index) => (
                            <React.Fragment key={unit}>
                                <span className='d-flex flex-column align-items-center fs-5'>
                                    <div style={{ fontSize: "300%" }} className='fw-medium text-dark'>
                                        {timeRemaining[unit]}
                                    </div>
                                    <div className='fw-light'>{unit.toUpperCase()}</div>
                                </span>
                                {index < 3 && <span className='fw-light fs-3'>:</span>}
                            </React.Fragment>
                        ))}
                    </div>
                </Col>
                <Col xl="4" className='my-3'>
                    <img
                        src='https://isomorphic-furyroad.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fcoming-soon.8078281f.png&w=1080&q=75'
                        alt="Coming Soon"
                        className='img-fluid'
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ComingSoon;
