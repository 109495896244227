import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import supplier from "../../../assets/supp.png"
import candidate from "../../../assets/candidate.png"

function Offers() {
  const { t } = useTranslation();

  const bgOrange = {
    background: "linear-gradient(180deg, #EE722E 0%, #f8ae85 100%)",
    overflow: "hidden",
  };

  const bgBlue = {
    background: "linear-gradient(180deg, #8077EC 0%, #E9E7FD 100%)",
    overflow: "hidden",
  };

  return (
    <Form as="section" className="container-lg py-3">

      <Row>
        <Col xl="6" className="my-3">
          <div style={bgBlue} className="rounded-4 text-white h-100 px-3">
            <Row className="justify-content-around">
              <Col md="6" lg="6" className="d-flex align-items-center">
                <div className="py-5">
                  <div className="fs-5 fw-medium">{t("vip_title")}</div>
                  <p className="my-3 fs-6">{t("vip_text")}</p>
                  <button className="bg-white text-dark p-3 rounded mt-3">
                    {t("become_vip")}
                  </button>
                </div>
              </Col>
              <Col md="3" lg="2" xl="3" className="px-0 d-md-block d-none">
                <img alt={t("vip_title")} src={candidate} width="100%" height="100%" />
              </Col>
            </Row>
          </div>
        </Col>

        <Col xl="6" className="my-3">
          <div style={bgOrange} className="rounded-4 text-white h-100 px-3">
            <Row className="justify-content-around">
              <Col md="6" lg="6" className="d-flex align-items-center">
                <div className="py-5">
                  <div className="fs-5 fw-medium">{t("suppliers")}</div>
                  <p className="my-3 fs-6">{t("supplier_text")}</p>
                  <button className="bg-white text-dark p-3 rounded mt-3">
                    {t("become_supplier")}
                  </button>
                </div>
              </Col>
              <Col md="3" lg="2" xl="3" className="px-0 d-md-block d-none">
                <img alt={t("become_supplier")} src={supplier} width="100%" height="100%" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default Offers;
