import ComingSoon from '../ComingSoon';

function Main(props) {

    return (
        <ComingSoon/>

    );

}
export default Main;