import { Route, Routes } from 'react-router-dom';
import ErrorPage from './pages/error/404'
import Home from './pages/home/Home'
import About from './pages/about/Home'
import DatePolicy from './pages/data_policy/Home'
import LegalInformation from './pages/legal_information/Home'
import Faq from './pages/faq/Home'
import Contact from './pages/contact/Home'
import HotelMain from './pages/hotels/Main'
import FlightHotelMain from './pages/flights_and_hotel/Main'
import ShoppingcartMain from './pages/shoppingcart/Main'
import BookingMain from './pages/booking/Main'
import PaymentMain from './pages/payment/Main'
import ActivityMain from './pages/activities/Main'
import TransferMain from './pages/transfers/Main'
import FlightMain from './pages/flights/Main'
import RegisterMain from './pages/register/Main'
import BlogMain from './pages/blog/Main'
import TravelMain from './pages/travel/Main'
function RouteComponents() {
    return (
        <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/about' exact element={<About />} />
            <Route path='/terms-and-conditions' exact element={<DatePolicy />} />
            <Route path='/legal/information' exact element={<LegalInformation />} />
            <Route path='/faq' exact element={<Faq />} />
            <Route path='/contact' exact element={<Contact />} />
            <Route path="/hotels">
                <Route path=':countryName/:cityName/:hotelName/:hotelId/:searchToken' element={<HotelMain component="details" />} />
                <Route path='search/:searchToken' element={<HotelMain component="search" />} />
                <Route path='search/map/:searchToken' element={<HotelMain component="searchMap" />} />
                <Route path='book/:hotelName/:hotelId/:searchToken/:rateKey' element={<HotelMain component="book" />} />
            </Route>
            <Route path="/activities">
                <Route path=':searchToken/:year/:month/:day/:countryName/:cityName/:activityName/:activityCode/:numberOfDay/:rateKey' element={<ActivityMain component="details" />} />
                <Route path='search/:searchToken' element={<ActivityMain component="search" />} />
                <Route path='book/:activityName/:activityCode/:searchToken/:rateKey' element={<ActivityMain component="book" />} />
            </Route>

            <Route path="/transfers">
                <Route path=':searchToken/:year/:month/:day/:countryName/:cityName/:activityName/:activityCode/:numberOfDay/:rateKey' element={<ActivityMain component="details" />} />
                <Route path='search/:searchToken' element={<TransferMain component="search" />} />
                <Route path='detail/:searchToken/:rateKey' element={<TransferMain component="detail" />} />
                <Route path='book/:searchToken/:rateKey' element={<TransferMain component="book" />} />
            </Route>

            <Route path="/flights">
                <Route path=':searchToken/:carrierCode/:airline/:day/:month/:year/:rateKey' element={<FlightMain component="details" />} />
                <Route path='search/:searchToken/:day/:month/:year' element={<FlightMain component="search" />} />
                <Route path='book/:searchToken/:carrierCode/:airline/:day/:month/:year/:rateKey' element={<FlightMain component="book" />} />
            </Route>

            <Route path="/travels">
                <Route path='' element={<FlightHotelMain component="home" />} />
            </Route>

            <Route path="/shoppingcart">
                <Route path='' element={<ShoppingcartMain component="home" />} />
                <Route path='voucher' element={<ShoppingcartMain component="voucher" />} />
            </Route>

            <Route path="/payment">
                <Route path='fail/:transactionId' element={<PaymentMain component="fail" />} />
                <Route path='success/:transactionId' element={<PaymentMain component="success" />} />
            </Route>

            <Route path="/booking">
                <Route path='detail/:bookingNumber/:token' element={<BookingMain component="detail" />} />

            </Route>

            <Route path="/register">
                <Route path='' element={<RegisterMain component="home" />} />
                <Route path='suppliers' element={<RegisterMain component="suppliers" />} />
            </Route>

            <Route path="/blog">
                <Route path=':articletId' element={<BlogMain component="details" />} />
                <Route path='' element={<BlogMain component="search" />} />
                <Route path='type/:articleType' element={<BlogMain component="search_type" />} />
            </Route>

            <Route path="/travel">
                <Route path='secure/mytriplogin' element={<TravelMain component="home" />} />
                <Route path='settings' element={<TravelMain component="settings" />} />
            </Route>


            <Route path='*' element={<ErrorPage />} />
        </Routes>

    )

}
export default RouteComponents;