import React from "react";
import Activity from "../../../pages/activities/forms/Availability";
import Transfer from "../../../pages/transfers/forms/Availability";
import Hotel from "../../../pages/hotels/forms/Availability";
import Flight from "../../../pages/flights/forms/Availability";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import ITabs from "../../../components/ITabs.js";


const MainForm = ({ setComponent }) => {

  const { t } = useTranslation();
  const navItems = [
    {
      "id": "flights",
      "name": t('flights'),
      "component": <Flight setComponent={setComponent} />
    },
    {
      "id": "hotels",
      "name": t('hotels'),
      "component": <Hotel setComponent={setComponent} />
    },
    {
      "id": "activities",
      "name": t('activities'),
      "component": <Activity setComponent={setComponent} />
    },
    {
      "id": "transfers",
      "name": t('transfers'),
      "component": <Transfer setComponent={setComponent} />
    }

  ]

  return (
    <Form as="section" className="w-100 container">
      <ITabs navItems={navItems} container={'home-forms'} selected={0} />
    </Form>
  )

}

export default MainForm;
