import ComingSoon from "../ComingSoon";

const Home = () => {

  return (
    <>
     <ComingSoon/>
    </>
  );
};

export default Home;
