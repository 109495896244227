import { useState } from "react";
import ResultAvailability from "../../components/model/ResultAvailability";
import Availability from "./forms/Availability";
import { configSearch, getList } from "./Actions";
import { useSelector } from "react-redux";
 
function Search() {
  const [component, setComponent] = useState(<></> );
  const hotel = useSelector((state) => state.hotelIdhtours28062024);
 
  return (<ResultAvailability  dataPost={hotel.dataPost} forms={<Availability setComponent={setComponent}/>} getList={getList} configSearch={configSearch}/>)
}
export default Search;
