import { send } from "../../global/axios";
export function configSearch(dataPost,searchToken,t){
   
    const config = {
        dataPost: dataPost,
        service: 'flight',
        searchToken: searchToken,
        day: 'day',
        messageFilter: t('no_flight_matches_the_criteria_selected_filters'),
        request: {
            list: { service: 'flight', action: "list", data: {},urlAppend:[searchToken,1] },
            filter: { service: 'flight', action: "filter", data: { filter: []} ,urlAppend:[searchToken]},
            paginate: { service: 'flight', action: "paginate", data: {} ,urlAppend:[searchToken,1]} 
        },
        toolBarItems: [
            { id: "prices_flight", name: t('price'), fiter_name: 'PRICES_ORDER' } 
        ],
        filterItems: [          
           { id: "airline", title: t('airlines'), action: 'Standard' } ,
           { id: "stops", title: t('escales'), action: 'GoReturn' },
           { id: "duration", title: t('duration'), action: 'GoReturn' },
           { id: "times", title: t('hours'), action: 'GoReturn' },
           { id: "airport", title: t('airports'), action: 'GoReturn' },
           { id: "airportStops", title: t('airport_tops'), action: 'GoReturn' }
        ]
    }

    return  config;
}

export const  getAvailability = async (dataPost)=>{
return await send({service: "flight", action: "load", data: {dataPost:dataPost}});     
}
export const  getList = async (searchToken,day,month,year)=>{
return await send( { service: 'flight', action: "list", data: {},urlAppend:[searchToken,day,month,year,1] });}
export const getCheckRate = async (rateKey, searchToken,day,month,year) => {
return await send({ service: "flight", action: "chek_rate", data: {}, urlAppend: [searchToken,day,month,year,rateKey] });   
}; 

export const getDetails = async (rateKey, searchToken,day,month,year) => {
return await send({ service: "flight", action: "detail", data: {}, urlAppend: [searchToken, day,month,year,rateKey] });   
};  

export  const addToPanier = async (data,searchToken,rateKey) => {     
    document.getElementById("loader").style.display = "block";
const res= await send( { service: 'flight', action: "prebooking", data: {dataBook:data}, urlAppend:[ searchToken,rateKey] });        
if (res?.data?.status == true && res?.data?.result?.preBooking !='' && res?.data?.result?.preBooking !=null) {
window.location="/shoppingcart"

}else{
alert('desactiver temporairement')
} 
document.getElementById("loader").style.display = "none";
};