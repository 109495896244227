import { Search, Trash2 } from "lucide-react";
import React, { useState } from "react";
import Guests from "./Guests";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import InputAutocomplete from "../../../components/forms/InputAutocomplete";
import DateInput from "../../../components/forms/DateInput";
import { useTranslation } from "react-i18next";
import { autocomplete } from "../../../global/Autocomplete";
import { dateEn } from "../../../global/global";
import { isValid } from "date-fns";
import { stringify } from "querystring";
import { useNavigate } from "react-router-dom";
import { getAvailabiity } from "../Actions";
import NoAvailability from "../../../components/NoAvailability";

const delay = ms => new Promise(res => setTimeout(res, ms));

const Availability = ({ setComponent }) => {



  const hotel = useSelector((state) => state.hotelIdhtours28062024);
  /**
   * utilisé pour l'autocomplete
   */
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showGuests, setShowGuests] = useState(false);

  const handleCloseGuests = () => setShowGuests(false);
  const handleShowGuests = () => setShowGuests(true);

  const [rooms, setRooms] = useState(hotel.dataPost.rooms);
  const [showbtnadd, setShowBtnAdd] = useState(true);
  const [adultCount, setAdultCount] = useState(hotel.dataPost.adultCount);
  const [childCount, setChildCount] = useState(hotel.dataPost.childCount);
  const [roomsCount, setRoomsCount] = useState(hotel.dataPost.roomsCount);
  const removeRoom = (index) => {
    var roomId = parseInt(index);
    var room = rooms[roomId];
    var child = room.child;
    var adult = room.adult;
    _setAdultCount(adult, 0);
    _setChildCount(child, 0);
    const updatedRooms = rooms.filter((room, i) => i !== index);
    setRooms(updatedRooms);
    _setRoomsCount(-1);
    setShowBtnAdd(true);
    updateRooms();
  };
  const updateRooms = () => {
    var dataPost = hotel?.dataPost;
    dataPost["rooms"] = rooms;

  };

  const _setRoomsCount = (nb) => {
    var new_value = parseInt(roomsCount) + parseInt(nb);
    setRoomsCount(new_value);
  };

  const _setAdultCount = (lastValue, value) => {
    var new_value = parseInt(adultCount) - parseInt(lastValue) + parseInt(value);
    setAdultCount(new_value);
  };

  const _setChildCount = (lastValue, value) => {
    var new_value = parseInt(childCount) - parseInt(lastValue) + parseInt(value);
    setChildCount(new_value);
  };

  const addRoom = () => {
    const newRoom = { adult: 1, child: 0, childAge: [] };
    setRooms([...rooms, newRoom]);
    _setRoomsCount(1);
    _setAdultCount(0, 1);

    if (rooms.length + 1 == hotel.maxRooms) {
      setShowBtnAdd(false);
    }
  };
  const rooming = () => {
    return (
      <>
        {rooms.map((room, index) => {
          var nb = parseInt(index) + 1;
          var id = "room_" + index;
          return (
            <Form as="div" id={id} key={id} className="mb-3 d-flex flex-column">
              {/* <Form as="span"  className='fw-bold fs-6' style={{ color: "var(--primary-blue)" }}>Room {nb}</Form>  */}
              <Form as="div" className="row" id={"occupancy_" + id}>
                <Form as="div" className="col-md-6">
                  {room_adult(room, index)}
                </Form>
                <Form as="div" className="col-md-6">
                  {room_child(room, index)}
                </Form>

                {room_age_child(room, index)}
              </Form>

              <Form as="div" className="align-self-end" style={{ top: "0", right: "0" }}>
                {btn_delete(index)}
              </Form>
            </Form>
          );
        })}
      </>
    );
  };

  const handleAdultesChange = (event) => {
    var roomId = parseInt(event.target.dataset.roomIndex);
    var room = rooms[roomId];
    var lastValue = room.adult;
    var newValue = event.target.value;
    _setAdultCount(lastValue, newValue);
    room.adult = newValue;
    rooms[roomId] = room;
    setRooms(rooms);
  };

  const handleChildChange = (event) => {
    var roomId = parseInt(event.target.dataset.roomIndex);
    var room = rooms[roomId];
    var lastValue = room.child;
    var newValue = event.target.value;

    _setChildCount(lastValue, newValue);
    room.child = newValue;
    var diff = parseInt(newValue) - parseInt(lastValue);
    if (diff <= 0) {
      var d = parseInt(newValue) - 1;
      if (newValue == 0) {
        d = 0;
      }
      room.childAge.splice(d, Math.abs(diff));
    } else {
      for (let i = lastValue; i < newValue; i++) {
        room.childAge.push(1);
      }
    }
    room_age_child(room, roomId);
    rooms[roomId] = room;
    setRooms(rooms);
  };

  const setChildAge = (event) => {
    var roomId = parseInt(event.target.dataset.roomIndex);
    var ageIndex = parseInt(event.target.dataset.ageIndex);
    rooms[roomId]["childAge"][ageIndex] = event.target.value;
  };

  const btn_delete = (index) => {
    if (index == 0) {
      return;
    }
    var id = "room_" + index;
    return (
      <Form as="div" className="border text-danger p-2 rounded-5" style={{ cursor: "pointer" }} onClick={() => removeRoom(index)}>
        <Trash2 size={20} />{" "}
        <Form as="span" className="text-dark">
          {t("delete")}
        </Form>
      </Form>
    );
  };

  const room_adult = (room, index) => {
    var html = "";
    for (let i = 1; i < 5; i++) {
      html += '<option value="' + i + '"  >' + i + " " + "</option > ";
    }
    return (
      <Form as="div" className="d-flex flex-column my-3">
        <label htmlFor={`adults${index}`} className="fw-bold fs-6">
          {t("adults")}{" "}
          <Form as="span" className="guests-notation">
            {t("over_12_years_old")}
          </Form>
        </label>
        <select id={`adults${index}`} className="input-blue border mt-1 p-3 rounded" name={"rooms[" + index + "][adult]"} defaultValue={room.child} onChange={handleAdultesChange} data-room-index={index}>
          {parse(html)}
        </select>
      </Form>
    );
  };

  const room_child = (room, index) => {
    var html = "";
    for (let i = 0; i <= 4; i++) {
      html += '<option value="' + i + '"  >' + i + " " + "</option > ";
    }

    return (
      <Form as="div" className="d-flex flex-column my-3">
        <label htmlFor={`childs${index}`} className="fw-bold fs-6">
          {t("childs")}{" "}
          <Form as="span" className="guests-notation">
            {t("from_0_to_11_years_old")}
          </Form>
        </label>
        <select id={`childs${index}`} className="input-blue border mt-1 p-3 rounded" name={"rooms[" + index + "][child]"} defaultValue={room.child} onChange={handleChildChange} data-room-index={index}>
          {parse(html)}
        </select>
      </Form>
    );
  };

  const room_age_child = (room, index) => {
    var html = "";

    for (let i = 1; i <= 11; i++) {
      html += i > 1 ? '<option value="' + i + '">' + i + " ans</option>" : '<option value="' + i + '">' + i + " an</option>";
    }

    return (
      <>
        {room.childAge.map((age, j) => {
          return (
            <Form as="div" className="col-md-3 my-3" key={"div_age_" + index + "_" + j}>
              <select className="w-100 p-3 input-blue border rounded" data-age-index={j} data-room-index={index} defaultValue={age} key={"age_" + index + "_" + j} onChange={setChildAge}>
                {parse(html)}
              </select>
            </Form>
          );
        })}
      </>
    );
  };

  /**
   * la fonction qui lance l'autocomplete
   * @param {string} query
   */
  const handleAutocomplte = (query: string) => {
    const req = { service: "autocomplete", action: "CITYBOOKING", data: { q: query } };
    setIsLoading(true);
    autocomplete(req, setOptions, setIsLoading);
  };
  /**
   * autocomplete selected
   * @param {object} items
   */
  const handleAutocomplteSelect = (item) => {
    if (item.length > 0) {
      hotel.dataPost.country_id = item[0]["country_id"].toUpperCase();
      hotel.dataPost.destination = item[0]["destination"];
      hotel.dataPost.city_id = item[0]["city_id"];
      hotel.dataPost.id = item[0]["id"];
      hotel.dataPost.type = item[0]["type"];

      hotel.dataPost.city_name = item[0]["city_name"];
      hotel.dataPost.country_name = item[0]["country_name"];

    }
  };

  /**
   * nationality selected
   * @param {object} items
   */
  const handleNationalitySelect = (item) => {
    if (item.length > 0) {
      hotel.dataPost.nationality = item[0]["code"];

    }
  };

  const updateDataPost = () => {
    dispatch(
      setDataPost({
        ...hotel?.dataPost,
        ...hotel.dataPost,
      })
    );
  };

  const filterBy = () => true;

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!ctrlDataPost()) {
      return false;
    }
    setLoading(true);

    dispatch(setAvailability([]));
    const res = await getAvailabiity(hotel.dataPost);



    if (res?.data?.result?.searchToken != "") {
      var url = ["/hotels", "search", res?.data?.result?.searchToken];
      window.history.pushState(null, "Title", url.join("/") + "?" + stringify(hotel.dataPost));
      window.location.reload()
    } else {
      setComponent(<NoAvailability />);
    }

    setLoading(false);
  };

  const ctrlDataPost = () => {
    if (hotel?.dataPost.id == "" || hotel?.dataPost.city_id == "" || hotel?.dataPost.country_id == "" || hotel?.dataPost.type == "" || hotel?.dataPost.estination == "") {
      alert(t("select_a_destination"));
      return false;
    }

    if (isValid(new Date(dateEn(hotel.dataPost.checkin))) == false || isValid(new Date(dateEn(hotel.dataPost.checkout))) == false) {
      alert(t("invalid_forma"));
      return false;
    }

    hotel.dataPost.roomsCount = roomsCount;
    hotel.dataPost.adultCount = adultCount;
    hotel.dataPost.childCount = childCount;
    hotel.dataPost.rooms = rooms;

    updateDataPost();
    return true;
  };

  const onDateChange = (dates) => {
    if (!dates) { return }

    if (dates[0]) {
      hotel.dataPost.checkin = new Date(dates[0]).toLocaleDateString("fr-FR");
    }
    if (dates[1]) {

      hotel.dataPost.checkout = new Date(dates[1]).toLocaleDateString("fr-FR");
    }
  };

  return (
    <Form as="section" className="container">
      <form onSubmit={onSubmit} id="form-hotel-availability">
        <Row className="align-items-center">
          <Col md="6" lg="3" className="my-2">
            <InputAutocomplete views={'hotel'} id="location" label={t("destination_place")} placeholder={t("where_are_you_going")} value={hotel.dataPost.destination} filterBy={filterBy} isLoading={isLoading} handleSearch={handleAutocomplte} handleClick={handleAutocomplteSelect} options={options} selected={[hotel.dataPost]} className="rbt-input-no-border border input-blue bg-white" />
          </Col>
          <Col md="6" lg="4" className="my-2">
            <DateInput format="DD/MM/YYYY" leftLabel={t("check_in")} rightLabel={t("check_out")} checkin={hotel.dataPost.checkin} checkout={hotel.dataPost.checkout} onDateChange={onDateChange} />
          </Col>

          <Col md="6" lg="3" className="my-2">
            <Form role="button" onClick={handleShowGuests} as="div" className="bg-white input-blue text-secondary border d-flex justify-content-center align-items-center" style={{ height: "1.8cm" }}>
              {roomsCount} {roomsCount === 1 ? t("room") : t("rooms")} | {adultCount + childCount} {adultCount + childCount === 1 ? t("traveler") : t("travelers")}
            </Form>
          </Col>

          <Col md="6" lg="2" className="my-2">
            <button className="btn-orange text-dark p-3 w-100" style={{ height: "1.8cm" }}>
              {loading ? <Form as="span" className="spinner-border spinner-border-sm m-1"></Form> : <Search size={20} />}
            </button>
          </Col>
        </Row>
      </form>
      <Guests showGuests={showGuests} handleCloseGuests={handleCloseGuests} handleShowGuests={handleShowGuests} rooming={rooming} showbtnadd={showbtnadd} addRoom={addRoom} roomsCount={roomsCount} handleNationalitySelect={handleNationalitySelect} />
    </Form>
  );
};

export default Availability;

const setDataPost = (value) => {
  return {
    type: "data_post",
    payload: value,
  };
};

const setAvailability = (value) => {
  return {
    type: "set_availability",
    payload: value,
  };
};
