import SearchType from './SearchType';
import ComingSoon from '../ComingSoon';


function Main(props) {

    const composite = {
        search: <ComingSoon/>,
        details:<ComingSoon/>,
        search_type:<SearchType/>
    };

    return (
        <>{composite[props.component]}</>

    );

}
export default Main;