import ComingSoon from "../ComingSoon";




function Home() {


  return (
    <>
      <ComingSoon/>
    </>
  );
}

export default Home;
