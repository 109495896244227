import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Search} from "lucide-react";
import { autocomplete } from "../../../global/Autocomplete";
import InputAutocomplete from "../../../components/forms/InputAutocomplete";
import DateInput from "../../../components/forms/DateInput";
import Guests from "./Guests";
import { dateEn } from "../../../global/global"; 
import { stringify } from "querystring"; 
import { getAvailability } from "../Actions";
import NoAvailability from "../../../components/NoAvailability";
 
const Availability = ({ setComponent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const activity = useSelector((state) => state.activityIdhtours28062024);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [showGuests, setShowGuests] = useState(false);
  const [AdultsCount, setAdultsCount] = useState(activity.dataPost.Adult);
  const [ChildsCount, setChildsCount] = useState(activity.dataPost.Child);
  const handleCloseGuests = () => setShowGuests(false);
  const handleShowGuests = () => setShowGuests(true);

  const handlesetAdultsCount = (event) => {
    setAdultsCount(event.target.value);
    activity.dataPost.Adult = parseInt(event.target.value);
  };
  const handleSetChildAge = (event) => {
    var ageIndex = parseInt(event.target.dataset.ageIndex);
    activity["dataPost"]["ChildAge"][ageIndex] = event.target.value;
  };
  const handlesetChildsCount = (event) => {
    setChildsCount(event.target.value);
    var lastValue = parseInt(activity.dataPost.Child);
    var newValue = parseInt(event.target.value);
    activity.dataPost.Child = newValue;
    var diff = newValue - lastValue;

    if (diff <= 0) {
      var d = newValue - 1;
      if (newValue == 0) {
        d = 0;
      }
      activity.dataPost.ChildAge.splice(d, Math.abs(diff));
    } else {
      for (let i = lastValue; i < newValue; i++) {
        activity.dataPost.ChildAge.push(1);
      }
    }
  };

  const GuestsCount = parseInt(AdultsCount) + parseInt(ChildsCount);

  /**
   * la fonction qui lance l'autocomplete
   * @param {string} query
   */
  const handleAutocomplte = (query: string) => {
    const req = { service: "autocomplete", action: "cities", data: { q: query } };
    setIsLoading(true);
    autocomplete(req, setOptions, setIsLoading);
  };
  /**
   * autocomplete selected
   * @param {object} items
   */
  const handleAutocomplteSelect = (item) => {
    if (item.length > 0) {
      activity.dataPost.CountryId = item[0]["country_id"];
      activity.dataPost.destination = item[0]["destination"];
      activity.dataPost.DestinationId = item[0]["id"];
    }
  };
  const filterBy = () => true;

  /**
   * nationality selected
   * @param {object} items
   */
  const handleNationalitySelect = (item) => {
    if (item.length > 0) {
      activity.dataPost.Nationality = item[0]["code"];
      activity.dataPost.CountryOfResidence = item[0]["code"];
    }
  };
  const updateDataPost = () => {
    dispatch(
      setDataPost({
        ...activity?.dataPost,
        ...activity.dataPost,
      })
    );
  };

  //start search
  const onStartSearchActivity = async (event) => {
    event.preventDefault();
    if (!ctrlDataPost()) {
      return false;
    }
 
  
    dispatch(setAvailability([]));
    setLoading(true);

    const res = await getAvailability(activity.dataPost);
 
    if (res?.data?.status == true) {
      var url = ["/activities", "search", res?.data?.result?.searchToken];
      window.history.pushState(null, "Title", url.join("/") + "?" + stringify(activity.dataPost));
      window.location.reload()
     } else {
      setComponent(<NoAvailability />);
    }

    setLoading(false);
  };


  const onDateChange = (dates) => {
    if(!dates){return}

    if (dates[0]) {
      activity.dataPost.checkin = new Date(dates[0]).toLocaleDateString("fr-FR");
    }
    if (dates[1]) {
  
      activity.dataPost.checkout = new Date(dates[1]).toLocaleDateString("fr-FR");
    } 
  };



  const ctrlDataPost = () => {
    if (activity?.dataPost.DestinationId == "" || activity?.dataPost.CountryId == "" || activity?.dataPost.estination == "") {
      alert(t("select_a_destination"));
      return false;
    }
   
    activity.dataPost.DateFrom = dateEn(activity.dataPost.checkin );
    activity.dataPost.DateTo = dateEn(activity.dataPost.checkout );
 

    updateDataPost();
    return true;
  };
  return (
    <Form as="section" className="container">
      <form onSubmit={onStartSearchActivity} id="form-activity-availability">
        <Row className="align-items-center">
          <Col lg="3" className="my-2">
            <InputAutocomplete id="location-activity" label={t("destination_place")} placeholder={t("where_are_you_going")}  value={activity.dataPost.destination} filterBy={filterBy} isLoading={isLoading} handleSearch={handleAutocomplte} handleClick={handleAutocomplteSelect} options={options} selected={[activity.dataPost]} className="rbt-input-no-border border input-blue bg-white"  views={'activity'}/>
          </Col>
          <Col lg="4" className="my-2">
            <DateInput id="date-activity" leftLabel={t("starting_date")} rightLabel={t("ending_date")} checkin={activity.dataPost.checkin} checkout={activity.dataPost.checkout}  onDateChange={onDateChange}/>
          </Col>
          <Col lg="3" className="my-2">
            <Form role="button" onClick={handleShowGuests} as="div" className="bg-white input-blue text-secondary border d-flex justify-content-center align-items-center" style={{ height: "1.8cm" }}>
              {AdultsCount} {parseInt(AdultsCount) === 1 ? t("ADT") : t("adults")} | {ChildsCount} {parseInt(ChildsCount) === 1 ? t("CHD") : t("childs")}
            </Form>
          </Col>
          <Col lg="2" className="my-2">
            <Form as="div" className="d-flex">
              <button className="btn-orange text-dark p-3 w-100" style={{ height: "1.8cm" }}>
                {loading ? <Form as="span" className="spinner-border spinner-border-sm m-1"></Form> : <Search size={20} />}
              </button>
            </Form>
          </Col>
        </Row>
        <Guests showGuests={showGuests} handleCloseGuests={handleCloseGuests} handleSetChildAge={handleSetChildAge} handleShowGuests={handleShowGuests} AdultsCount={AdultsCount} handlesetAdultsCount={handlesetAdultsCount} ChildsCount={ChildsCount} handlesetChildsCount={handlesetChildsCount} GuestsCount={GuestsCount} handleNationalitySelect={handleNationalitySelect} ChildAge={activity.dataPost.ChildAge} />
      </form>
    </Form>
  );
};

export default Availability;

const setDataPost = (value) => {
  return {
    type: "data_post_activity",
    payload: value,
  };
};

const setAvailability = (value) => {
  return {
    type: "set_availability_activity",
    payload: value,
  };
};

 