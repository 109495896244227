import { Row, Col, Form } from "react-bootstrap";
import { Facebook, Instagram, Twitter, Linkedin, Mail, Phone, MapPin } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import NewsLetter from "./NewsLetter";
import { BiLogoPlayStore, BiLogoApple } from "react-icons/bi";

function Footer() {

  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  if (location.pathname === "/dashboard") {
    return null;
  }

  const networks = [
    { title: "Facebook", icon: <Facebook size={20} color="#212529" fill="#212529" strokeWidth={1.5} />, link: process.env.REACT_APP_FACEBOOK },
    { title: "Instagram", icon: <Instagram size={20} color="#212529" strokeWidth={1.5} />, link: process.env.REACT_APP_INSTEGRAM },
    { title: "Twitter", icon: <Twitter size={20} color="#212529" fill="#212529" strokeWidth={1.5} />, link: process.env.REACT_APP_TWITTER },
    { title: "Linkedin", icon: <Linkedin size={20} color="#212529" fill="#212529" strokeWidth={1.5} />, link: process.env.REACT_APP_LINKEDIN },

  ]

  const cards = ["https://viatour-nextjs.vercel.app/_next/image?url=%2Fimg%2Ffooter%2Fcards%2F1.png&w=48&q=75", "https://viatour-nextjs.vercel.app/_next/image?url=%2Fimg%2Ffooter%2Fcards%2F2.png&w=48&q=75", "https://viatour-nextjs.vercel.app/_next/image?url=%2Fimg%2Ffooter%2Fcards%2F4.png&w=48&q=75", "https://viatour-nextjs.vercel.app/_next/image?url=%2Fimg%2Ffooter%2Fcards%2F5.png&w=48&q=75", "https://viatour-nextjs.vercel.app/_next/image?url=%2Fimg%2Ffooter%2Fcards%2F6.png&w=48&q=75"]

  return (
    <footer className="bg-white text-dark">
      <NewsLetter />
      <div className="py-5">
        <Form as="div" className="container py-sm-5">
          <Row className="align-items-center">
            <Col lg="5">
              <Form as="div" className="d-flex align-items-center">
                <Form as="span" className="border-dark border-1 rounded-circle d-flex align-items-center justify-content-center" style={{ width: "40px", height: "40px" }}><Phone size={15} fill="#212529" /></Form>
                <Form as="span" className="mx-3 fw-light fs-6">
                  {t("speak_to_expert")}
                  <Form as="span" className="text-blue mx-2 fs-5 fw-medium">
                    {process.env.REACT_APP_CONTACT_PHONE}
                  </Form>
                </Form>
              </Form>
            </Col>
            <Col lg="7">
              <Form as="div" className="d-flex justify-content-end align-items-center fs-6 fw-light">
                <Form as="span">{t("follow_us")}</Form>
                <Form as="div" className="mx-3 d-flex">
                  {networks.map((link, index) => (
                    <a key={index} className="me-3 text-white" aria-label="Lien vers nos réseaux sociaux" href={link.link}>
                      {link.icon}
                    </a>
                  ))}
                </Form>
              </Form>
            </Col>
          </Row>
        </Form>
        <Form as="div" className="container">
          <Row className="justify-content-between">
            <Col lg="3">
              <Form as="div" className="fs-6 fw-light">
                <Form as="div" className="fs-5 fw-medium">
                  {t("contact_us")}
                </Form>
                <Form as="div" className="mt-4">
                  <Form as="div" className="d-flex align-items-center">
                    <span className="text-blue"><MapPin strokeWidth={1.5} size={20} /> </span><span className="mx-2">{process.env.REACT_APP_CONTACT_ADRESS}</span>
                  </Form>

                  <Form as="div" className="my-3 d-flex align-items-center">
                    <span className="text-blue"><Mail strokeWidth={1.5} size={20} /> </span><span className="mx-2">{process.env.REACT_APP_CONTACT_EMAIL}</span>
                  </Form>
                  <Form as="p" className="my-4">
                    {t("footer_text")}
                  </Form>
                </Form>
              </Form>
            </Col>
            <Col lg="3">
              <Form as="div" className="fs-6 fw-light">
                <Form as="span" className="fs-5 fw-medium">
                  {t("company")}
                </Form>
                <Form as="nav" className="d-flex flex-column mt-4">
                  <Form as="a" href="/about" className="mb-1 text-dark">
                    {t("about")}
                  </Form>
                  <Form as="a" href="/contact" className="my-1 text-dark">
                    {t("contact")}
                  </Form>
                  <Form as="a" href="/faq" className="my-1 text-dark">
                    {t("faq")}
                  </Form>
                  <Form as="a" href="/legal/information" className="my-1 text-dark">
                    {t("mentions_legales")}
                  </Form>
                  <Form as="a" href="/terms-and-conditions" className="my-1 text-dark">
                    {t("terms_and_conditions")}
                  </Form>
                </Form>
              </Form>
            </Col>
            <Col lg="3">
              <Form as="div" className="fs-6 fw-light">
                <Form as="span" className="fs-5 fw-medium">
                  {t("services")}
                </Form>
                <Form as="nav" className="d-flex flex-column mt-4">
                  <Form as="div" className="mb-1">
                    {t("flights")}
                  </Form>
                  <Form as="div" className="my-1">
                    {t("hotels")}
                  </Form>
                  <Form as="div" className="my-1">
                    {t("activities")}
                  </Form>
                  <Form href="/transferts" className="my-1">
                    {t("cars")}
                  </Form>
                  <Form as="div" className="my-1">
                    {t("articles")}
                  </Form>
                </Form>
              </Form>
            </Col>
            <Col lg="2">
              <Form as="div" className="fw-light">
                <Form as="span" className="fs-5 fw-medium">
                  {t("Mobile")}
                </Form>
                <Form as="nav" className="d-flex flex-column mt-4">
                  <Form as="div" className="border p-3 d-flex my-2">
                    <span className="icon-apple"><BiLogoPlayStore size={30} /></span>
                    <span className="mx-3">
                      <div className="fw-light">Download on</div>
                      <div className="fw-medium">Apple Store</div>
                    </span>
                  </Form>

                  <Form as="div" className="border p-3 d-flex my-2">
                    <span className="icon-apple"><BiLogoApple size={30} /></span>
                    <span className="mx-3">
                      <div className="fw-light">Get in on</div>
                      <div className="fw-medium">Google Play</div>
                    </span>
                  </Form>
                </Form>
              </Form>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="border-top">
        <Form as="div" className="container py-4">
          <Row className="justify-content-between align-items-center">
            <Col md="6" className="my-1">
              <div className="fs-5 fw-light"> © {currentYear} <span className="text-blue">Mall of travels</span>. {t("all_rights_reserved")}</div>
            </Col>
            <Col md="4" xl="3" className="my-1">
              <div className="d-flex justify-content-between">
                {cards.map(img => (
                  <img className="border" alt="Illustration card de crédit" src={img} width="38" height="24" />
                ))}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </footer>
  );
}

export default Footer;
