import { Modal} from 'rsuite';
import React, { useState } from "react";
import {country} from "../../data/country"; 
import InputTypeahead from "./InputTypeahead";
import { useTranslation } from "react-i18next";
 

const ICountry = ({elment, item,control,errors,register}) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [nationalitySelections, setNationalitySelections] = useState(country());
    const [countryName, setCountryName] = useState(t("select_your_country"));
    const [countryId ,setCountryId] = useState('');
    var className = ""

    if(errors[elment]){
        className =  " form-control-invalid  is-invalid"  
     }       
     const handleNationalitySelect = (item) => {
      if (item.length > 0) {
        setCountryName(item[0]["name"])
        setCountryId(item[0]["code"]);
      } 
      setOpen(false)

     }
     
    return (
        <> 
      
          <div className="custom-select">
          <select  onClick={handleOpen} name={elment} id={elment} {...register(elment)} className="input-blue w-100 input-passenger border rounded">
          <option value={countryId} >
          {countryName}
          </option>
          </select>
          </div>   
           <Modal open={open} onClose={handleClose}>
          <Modal.Header>
          <Modal.Title>{t("select_your_country")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <InputTypeahead  id={elment}   labelKey="name" placeholder={t("select_your_country")} options={nationalitySelections} handleClick={handleNationalitySelect} size="lg" className="rbt-input-border" />
          </Modal.Body> 
          </Modal>
        </>
    );
}

export default ICountry