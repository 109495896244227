import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Availability from "./forms/Availability";
import { useTranslation } from "react-i18next";
import Recap from "./views/Recap";
import Itinerary from "./views/Itinerary";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import CancelPolicy from "../../components/model/CancelPolicy";
import RateComment from "../../components/model/RateComment";
import { getCheckRate } from "./Actions";
import { useNavigate } from "react-router-dom";
import { scrollApp } from "../../global/global";
import DetailsFaq from '../../components/DetailsFaq'

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
 
const queryClient = new QueryClient();

var searchToken, rateKey, carrierCode, airline,day,year,month;

function Details() {
  const { t } = useTranslation();
  searchToken = useParams().searchToken;
  rateKey = useParams().rateKey;
  carrierCode = useParams().carrierCode;
  airline = useParams().airline;
  day = useParams().day;
  month = useParams().month;
  year = useParams().year;
  return (
    <QueryClientProvider client={queryClient}>
       <Form as="section">
       <div className="bg-dark-blue py-3">
        <Availability />
      </div>
      <CheckRate />
      </Form>
    </QueryClientProvider>
  );
}

function CheckRate() {
  
  const { isLoading, error, data } = useQuery("repoData", () => getCheckRate(rateKey, searchToken,day,month,year));
  if (isLoading) return <Loader/>;
  if (error) return <CheckRateError />;

  if (data?.data?.status != true) {
    return <CheckRateError />;
  }

  return <DetailFlight flight={data.data.result} />;
}

function CheckRateError() {}

function DetailFlight({ flight }) {
  const flightPost = useSelector((state) => state.flightsIreducer28062024);
  const [isBook, setIsBook] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onCheckBook = ({ target: { checked } }) => {
    setIsBook(checked);
  };

  const getFormBooking = () => {
    var url = ["/flights", "book", searchToken, flight.carrierCode, flight.airline,day,month,year, rateKey];
    scrollApp();
    navigate(`${url.join("/")}`);
  };

  return (
   
      <Form as="div" className="container-xl p-3 my-3">
        <Row>
          <Col lg="8" className="my-3">
            <Form as="div" className="fw-bold text-dark">
              <Form as="div" className="bg-white rounded-4 border-blue" style={{ overflow: "hidden" }}>
                <Form as="div" className="text-center text-white fw-light bg-blue p-2 fs-6">
                  {t("flight_details")}
                </Form>
                {flight.itineraries.map((itinerary, index) => (
                  <Itinerary baggages={flight.baggages} cabinBagages={flight.cabinBaggages} itinerary={itinerary} flightPost={flightPost} key={index} index={index} />
                ))}
              </Form>
            </Form>

            <CancelPolicy items={flight.cancellationPolicy} />

            <RateComment items={flight.rateComment} title={t("conditions")} />

            <Form as="div" id="continue" className="rounded-4 p-3">
              <Form as="input" onChange={onCheckBook} type="checkbox" id="accepte_condition_rate" className="p-3" />
              <Form as="label" htmlFor="accepte_condition_rate" className="p-3 fs-6 fw-medium text-dark">
                {t("accepte_condition_rate")}
              </Form>
              <Form as="br" />

              {!isBook && (
                <Form as="button" className="btn-outline-blue px-5 p-3 rounded-5" disabled type="button">
                  {t("reserve")}
                </Form>
              )}

              {isBook && (
                <Form as="button" className="btn-blue px-5 p-3 rounded-5" onClick={getFormBooking} type="button">
                  {t("reserve")}
                </Form>
              )}
            </Form>
          </Col>
        
          <Col lg="4" className="my-3" id="recap-flight">
            <Recap flight={flight} />
          </Col>
        </Row>
        <hr className="border-secondary my-5"/>

        <DetailsFaq t={t} value="4" />
      </Form>
    
  );
}
export default Details;
