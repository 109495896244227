import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { MapPin, Star } from "lucide-react";
import { useTranslation } from "react-i18next";
import { PiHeartLight } from "react-icons/pi";
import { currencySymbol } from '../../../global/global'

const Hotels = () => {

  const { t } = useTranslation();

  const items = [
    { name: "The Elvis Villa by Stay Awhile Villas", img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/603487123.jpg?k=b47387626737cfb9eab9fb3cd75de1f81d98964c3fdc796f16efeb1068242842&o=&hp=1", adress: "Los Angeles, United-states", category: 5, amount: 408, currency: "EUR" },
    { name: "Los Angeles Premium Suites with Parking", img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/527125629.jpg?k=61d722f1445014b8f801f062878540a697af10bcf089409cc6a66465fc37cf2d&o=&hp=1", adress: "Hollywood, United-states", category: 4, amount: 329, currency: "EUR" },
    { name: "AP Embajadores XVI", img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/448179389.jpg?k=076b8c7e58ec78c134819d70f60712c54c46f12e940b8e6d0b4679bae6419c8c&o=&hp=1", adress: "Madrid, Spain", category: 3, amount: 516, currency: "EUR" },
    { name: "Citadines Bastille Marais Paris", img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/219077436.jpg?k=ffcbfb081ad1531ae6331e837590bce8471e2c24f670f8b6062accd34b354eae&o=&hp=1", adress: "Paris, France", category: 3, amount: 364, currency: "EUR" },
    { name: "Hotel 1550 - SFO Airport West", img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/270063455.jpg?k=b08f9c77d12625a348fa45036eb811354426cd9d02ef969a458beaef270e01c3&o=&hp=1", adress: "San Bruno, United-states", category: 3, amount: 214, currency: "EUR" },
    { name: "Olympic Hotel", img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/195158272.jpg?k=b34d2dc0df1d7c78b1a04cb8c15290d3de3e7f7f802c9827fa43663d8ac54497&o=&hp=1", adress: "Amsterdam, Netherlands", category: 4, amount: 785, currency: "EUR" },
  ]

  return (
    <div>
      <Row>
        {items.map((item, index) => (
          <Col key={index} md="6" lg="4" className="my-3">
            <a className="text-dark text-decoration-none" href="true" target="_blank" rel="noreferrer">
              <div className="border bg-white h-100 rounded-4">
                <img className="rounded-top-3" src={item.img} alt={`Illustration de l'activité - ${item.name}`} width="100%" height="260" style={{ maxHeight: "260px" }} />
                <div className="p-3 d-flex flex-column justify-content-between">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Form as="span" className="d-flex align-items-center bg-secondary bg-opacity-10 p-1 rounded">
                        <span className="text-blue"><MapPin strokeWidth={1.5} size={15} /></span>
                        <Form as="span" className="mx-1 text-capitalize">
                          {item.adress}
                        </Form>
                      </Form>
                      <span className="text-danger"><PiHeartLight size={20} /></span>
                    </div>
                    <div className="title-blue text-capitalize fs-5 fw-medium my-3">{item.name}</div>
                    <div className="d-flex align-items-center"><Star size={20} fill="#FFB700" color="#FFB700" /> <span className="mx-1">{item.category}</span></div>

                  </div>

                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span className="fs-4 fw-light text-dark">{item.amount}{currencySymbol(item.currency)}</span>
                    <span className="btn-outline-blue p-3 rounded-5">{t('view_details')}</span>
                  </div>
                </div>
              </div>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Hotels
