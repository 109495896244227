import { useTranslation } from "react-i18next";
import MainForm from "./MainForm";
import { Form } from "react-bootstrap";
import backgroundVideo from '../../../assets/icones/videoTRavel.mp4';

function Background({ setComponent }) {

  const { t } = useTranslation();

  return (
    <main className="py-5">
      <video className="video-background" autoPlay loop muted style={{ width: "100%", height: "100%", filter: "brightness(0.7)", objectFit: "cover" }}>
        <source src={backgroundVideo} type="video/mp4" />
        Votre navigateur ne prend pas en charge la vidéo.
      </video>

      <Form as="div" className="content">
        <Form as="div" className="text-white">
          <Form as="div" className="container d-flex flex-column align-items-center">
            <Form as="h1" className="text-center" style={{ fontSize: "500%" }}>
              {t("main_title9")}
            </Form>
            <Form as="h2" className="fs-5 my-2 text-center fw-light px-5">
              {t("main_slogan9")}
            </Form>
          </Form>
        </Form>
        <Form as="div" className="w-100 mt-5">
          <MainForm setComponent={setComponent} />
        </Form>
      </Form>
    </main>
  );
}

export default Background;
