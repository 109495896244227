import React from 'react'; 
import { Form } from 'react-bootstrap';


export default class ITabs extends React.Component {
    constructor(props) {
        super(props);      
        this.state = {
            navItems: props.navItems,
            selected: props.selected,
            container: props.container,
            content: props.navItems[ props.selected].component
        };

    }

    _setContent = (event, component) => {
        const T = document.getElementsByClassName('tab-active')
        T[0].classList.remove('tab-active');
        event.target.classList.add('tab-active')
        this.setState({
            content: component
        });
    } 
    


    render() {
        return (
            <Form as="section" className='iws-tab-nav'>
                <Form as="div" className="tabs d-flex align-items-center justify-content-center flex-wrap">
                    {
                        (this.state.navItems.map((item, index) => (

                            <Form as="div" 
                            className={`fs-6 py-1 my-1 mx-3 text-white tab fw-light ${(index === this.state.selected) ? 'tab-active' : ''}`}  
                            key={'tab-' + index}
                             onClick={(event) => this._setContent(event, item.component)} >
                                {item.name}
                            </Form>
                        )))
                    }
                </Form>
                <Form as="div" className="tab-content px-0 p-2 bg-white mt-3 rounded-4" id={'container-' + this.state.container}>
                
                {this.state.content}
                

                </Form>
            </Form >
        )

    }

}