import { ScreenShareOff } from "lucide-react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useSelector } from "react-redux";
import AvailabiltyComponent from "./AvailabiltyComponent";
import { useParams } from "react-router-dom";
import Loader from "../Loader";

const queryClient = new QueryClient();
var params;
function ResultAvailability(props) {
  params = useParams();
  return (
    <Form as="section" className="bg-body-tertiary">

      <Form as="div" className="py-3 bg-dark-blue">
        {props.forms}
      </Form>
      <Form as="div" className="container-xl py-3" id="container-avail">
        <Row>
          <Col lg="11">
            <QueryClientProvider client={queryClient}>
              <LoadItems configSearch={props.configSearch} getList={props.getList} dataPost={props.dataPost} />
            </QueryClientProvider>
          </Col>
          <Col lg="1" className="px-0 d-none d-md-block">
            <div className="my-3">
              <img alt="ad" src="https://tpc.googlesyndication.com/simgad/4655558844704971033?" width="100%" height="100%"/>

              <img className="my-3" alt="ad" src="https://tpc.googlesyndication.com/simgad/5330432893587462553?" width="100%" height="100%"/>

              <img className="my-3" alt="ad" src="https://tpc.googlesyndication.com/simgad/8066996277170319498?" width="100%" height="100%"/>
            </div>
          </Col>
        </Row>
      </Form>
    </Form>
  )

}

export default ResultAvailability;

function LoadItems({ dataPost, configSearch, getList }) {
  const { t } = useTranslation();
  const hotel = useSelector((state) => state.hotelIdhtours);
  const { isLoading, error, data } = useQuery("repoData", () => getList(params.searchToken, params.day, params.month, params.year));
  if (isLoading) return <Loader />;
  if (error) return <LoadError />;
  if (data?.data?.status != true) {
    return <LoadError />;
  }
  return <AvailabiltyComponent config={configSearch(dataPost, params.searchToken, t)} result={data?.data?.result} />;
}

function LoadError() {
  const { t } = useTranslation();
  return (
    <Form as="div" className="px-4 py-5 my-5 text-center text-dark">
      <Form as="div" className="text-blue">
        <ScreenShareOff size={60} strokeWidth={1.5} />
      </Form>
      <Form as="span" className="fs-1 fw-bold">{t("your_session_has_expired")}</Form>
      <Form as="div" className="col-lg-6 mx-auto mt-6">
        <Form as="p" className="lead mb-4">{t("your_session_has_expired_text")}</Form>
        <Form as="div" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
        </Form>
      </Form>
    </Form>
  )
}