import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { MapPin, Star } from "lucide-react";
import { useTranslation } from "react-i18next";
import { PiHeartLight } from "react-icons/pi";
import { currencySymbol } from '../../../global/global'

const Activity = () => {

  const { t } = useTranslation();

  const items = [
    { name: "Croisière sur l'architecture de 1 h 30", img: "https://cdn.getyourguide.com/img/tour/b4b30c0c6eccc80e.jpeg/145.jpg", adress: "Chicago, United-states", category: 4.8, amount: 40.7, currency: "EUR" },
    { name: "Visite du Lower Antelope Canyon", img: "https://cdn.getyourguide.com/img/tour/2e4d0cb57e34903fb432c839cbc76119dd90ca44b982e6276cc036322e347c19.jpeg/145.jpg", adress: "Arizona, United-states", category: 4.7, amount: 62.84, currency: "EUR" },
    { name: "Visite en yacht avec petit-déjeuner", img: "https://cdn.getyourguide.com/img/tour/43c2f7e85d6a511a.jpeg/145.jpg", adress: "Dubai, United-Arab-Emirates", category: 4, amount: 22, currency: "EUR" },
    { name: "Entrée au musée du louvre", img: "https://cdn.getyourguide.com/img/tour/0af3a8038d3121b1.jpeg/145.jpg", adress: "Paris, France", category: 4.8, amount: 23.11, currency: "EUR" },
    { name: "Croisière de 1h sur la Seine", img: "https://cdn.getyourguide.com/img/tour/cf879ee295abc8e4.jpeg/145.jpg", adress: "Paris, France", category: 4.3, amount: 17, currency: "EUR" },
    { name: "SUMMIT One Vanderbilt", img: "https://cdn.getyourguide.com/img/tour/6196b4ee0e055.jpeg/145.jpg", adress: "Newyork, United-states", category: 4.7, amount: 42.63, currency: "EUR" },
  ]

  return (
    <div>
      <Row>
        {items.map((item, index) => (
          <Col key={index} md="6" lg="4" className="my-3">
            <a className="text-dark text-decoration-none" href="true" target="_blank" rel="noreferrer">
              <div className="border bg-white h-100 rounded-4">
                <img className="rounded-top-3" src={item.img} alt={`Illustration de l'activité - ${item.name}`} width="100%" height="260" style={{ maxHeight: "260px" }} />
                <div className="p-3 d-flex flex-column justify-content-between">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Form as="span" className="d-flex align-items-center bg-secondary bg-opacity-10 p-1 rounded">
                        <span className="text-blue"><MapPin strokeWidth={1.5} size={15} /></span>
                        <Form as="span" className="mx-1 text-capitalize">
                          {item.adress}
                        </Form>
                      </Form>
                      <span className="text-danger"><PiHeartLight size={20} /></span>
                    </div>
                    <div className="title-blue text-capitalize fs-5 fw-medium my-3">{item.name}</div>
                    <div className="d-flex align-items-center"><Star size={20} fill="#FFB700" color="#FFB700" /> <span className="mx-1">{item.category}</span></div>

                  </div>

                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span className="fs-4 fw-light text-dark">{item.amount}{currencySymbol(item.currency)}</span>
                    <span className="btn-outline-blue p-3 rounded-5">{t('view_details')}</span>
                  </div>
                </div>
              </div>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Activity
