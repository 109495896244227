import { useTranslation } from "react-i18next";
import { MinusCircle, Plus, PlusCircle } from "lucide-react";
import { Form } from "react-bootstrap";

function Guests({ adultCount, childCount, infantCount, incrementAdultCount, decrementAdultCount, incrementChildCount, decrementChildCount, incrementInfantCount, decrementInfantCount }) {
  const { t } = useTranslation();
  const countsData = [
    { title: "adults", info: "(+11 ans)", value: adultCount, increment: incrementAdultCount, decrement: decrementAdultCount, min: 1, max: 6 },
    { title: "childs", info: "(2-11 ans)", value: childCount, increment: incrementChildCount, decrement: decrementChildCount, min: 0, max: 6 },
    { title: "infants", info: "(-2 ans)", value: infantCount, increment: incrementInfantCount, decrement: decrementInfantCount, min: 0, max: adultCount },
  ];

  return (
    <Form as="div" className="p-3">
      {countsData.map((item, index) => (
        <Form as="div" key={index} className="d-flex justify-content-between py-3 border-bottom  w-100">
          <Form as="div">
            <Form as="div" className="text-dark fs-6">
              {t(item.title)}{" "}
            </Form>
          </Form>
          <Form as="div" className="d-flex align-items-center">
            <Form as="button" className="mx-2 bg-white" onClick={item.decrement} disabled={item.value <= item.min}>
              <MinusCircle color="#3B71FE" strokeWidth={1.5} />
            </Form>
            <Form as="span" className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>
              {item.value}
            </Form>
            <Form as="button" className="mx-2 bg-white" onClick={item.increment} disabled={item.value >= item.max}>
              <PlusCircle color="#3B71FE" strokeWidth={1.5} />
            </Form>
          </Form>
        </Form>
      ))}
    </Form>
  );
}

export default Guests;
