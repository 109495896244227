import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, NavDropdown } from "react-bootstrap";
import { MapPin, Search } from "lucide-react";
import { autocomplete } from "../../../global/Autocomplete";
import InputAutocomplete from "../../../components/forms/InputAutocomplete";
import DateTimeInput from "../../../components/forms/DateTimeInput";
import Guests from "./Guests";
import { dateFr, dateTimeEn } from "../../../global/global";
import { config } from "../../../configs/config";

import { stringify } from "querystring";

import { getAvailability } from "../Actions";
import NoAvailability from "../../../components/NoAvailability";


const Availability = ({ setComponent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transfer = useSelector((state) => state.transferIdhtours28062024);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeDate, setRangeDate] = useState(transfer.rangeDate);
  const [options, setOptions] = useState([]);
  const [showGuests, setShowGuests] = useState(false);
  const [AdultsCount, setAdultsCount] = useState(transfer.dataPost.Adult);
  const [ChildsCount, setChildsCount] = useState(transfer.dataPost.Child);
  const [transferTypeActif, setTransferTypeActif] = useState(transfer.tripTitle);
  const handleCloseGuests = () => setShowGuests(false);
  const handleShowGuests = () => setShowGuests(true);

  const handlesetAdultsCount = (event) => {
    setAdultsCount(event.target.value);
    transfer.dataPost.Adult = parseInt(event.target.value);
  };
  const handleSetChildAge = (event) => {
    var ageIndex = parseInt(event.target.dataset.ageIndex);
    transfer["dataPost"]["ChildAge"][ageIndex] = event.target.value;
  };
  const handlesetChildsCount = (event) => {
    setChildsCount(event.target.value);
    var lastValue = parseInt(transfer.dataPost.Child);
    var newValue = parseInt(event.target.value);
    transfer.dataPost.Child = newValue;
    var diff = newValue - lastValue;

    if (diff <= 0) {
      var d = newValue - 1;
      if (newValue == 0) {
        d = 0;
      }
      transfer.dataPost.ChildAge.splice(d, Math.abs(diff));
    } else {
      for (let i = lastValue; i < newValue; i++) {
        transfer.dataPost.ChildAge.push(1);
      }
    }
  };

  const GuestsCount = parseInt(AdultsCount) + parseInt(ChildsCount);

  /**
   * la fonction qui lance l'autocomplete
   * @param {string} query
   */
  const handleAutocomplte = (query: string) => {
    const req = { service: "autocomplete", action: "terminal", data: { q: query } };
    setIsLoading(true);
    autocomplete(req, setOptions, setIsLoading);
  };
  /**
   * autocomplete selected
   * @param {object} items
   */
  const handleAutocomplteSelectFrom = (item) => {
    if (item.length > 0) {
      transfer.dataPost.From.Code = item[0]["code"];
      transfer.dataPost.From.Type = item[0]["type"];
      transfer.destination.From = item[0]["destination"];
    }
  };

  /**
   * autocomplete selected
   * @param {object} items
   */
  const handleAutocomplteSelectTo = (item) => {
    if (item.length > 0) {
      transfer.dataPost.To.Code = item[0]["code"];
      transfer.dataPost.To.Type = item[0]["type"];
      transfer.destination.To = item[0]["destination"];
    }
  };
  const filterBy = () => true;

  /**
   * nationality selected
   * @param {object} items
   */
  const handleNationalitySelect = (item) => {
    if (item.length > 0) {
      transfer.dataPost.Nationality = item[0]["code"];
      transfer.dataPost.CountryOfResidence = item[0]["code"];
    }
  };
  const updateDataPost = () => {
    dispatch(
      setDataPost({
        ...transfer?.dataPost,
        ...transfer.dataPost,
      })
    );
  };
  const handleChangeTransferType = (item) => {
    setTransferTypeActif(item.title);
    transfer.tripTitle = item.title;
    transfer.dataPost.Trip = item.code;
    if (item.code == 'RT') {
      setRangeDate(true)
    } else {
      setRangeDate(false)
    }
    transfer.rangeDate = rangeDate
  };

  //start search
  const onStartSearchtransfer = async (event) => {
    event.preventDefault();
    if (!ctrlDataPost()) {
      return false;
    }

    dispatch(setAvailability([]));

    setLoading(true);

    const res = await getAvailability(transfer.dataPost);

    if (res?.data?.status == true) {
      var url = ["/transfers", "search", res?.data?.result?.searchToken];
      window.history.pushState(null, "Title", url.join("/") + "?" + stringify(transfer.dataPost));
      window.location.reload()
    } else {
      setComponent(<NoAvailability />);
    }

    setLoading(false);
  };
  const onDateChange = (dates) => {


    if (!dates) { return }

    if (dates[0]) {
      transfer.dataPost.DateFrom = dateTimeEn(dates[0]);

    }
    if (dates[1]) {

      transfer.dataPost.DateReturn = dateTimeEn(dates[1]);
    }
  };

  const ctrlDataPost = () => {
    if (transfer?.dataPost.From.Code == "" || transfer?.dataPost.To.Code == "") {
      alert(t("select_a_destination"));
      return false;
    }


    updateDataPost();
    return true;
  };
  return (
    <Form as="section" className="container">
      <form onSubmit={onStartSearchtransfer} id="form-transfer-availability">
        <NavDropdown
          id="nav-flight-type"
          className="me-3 p-2"
          title={
            <Form as="span" className="text-blue me-2">
              {t(transferTypeActif)}
            </Form>
          }
          menuVariant="light">
          {config("transferType").map((item, index) => (
            <NavDropdown.Item onClick={() => handleChangeTransferType(item)} index={"tf-" + index} key={"tf-" + index}>
              {" "}
              {t(item.title)}
            </NavDropdown.Item>
          ))}
        </NavDropdown>



        <Row className="align-items-center ">
          <Col md="6" xl="3" className="my-2">
            <InputAutocomplete views={'transfer'} id="location-transfer" label={t("pick_up")} placeholder={t("where_are_you_leaving_from")} icon={<MapPin strokeWidth={1} size={20} className="form-icon" />} value={transfer.destination.From} filterBy={filterBy} isLoading={isLoading} handleSearch={handleAutocomplte} handleClick={handleAutocomplteSelectFrom} options={options} selected={[transfer.dataPost]} className="rbt-input-no-border border input-blue bg-white" />
          </Col>
          <Col md="6" xl="3" className="my-2">
            <InputAutocomplete views={'transfer'} id="destination-transfer" label={t("destination")} placeholder={t("where_are_you_going")} icon={<MapPin strokeWidth={1} size={20} className="form-icon" />} value={transfer.destination.To} filterBy={filterBy} isLoading={isLoading} handleSearch={handleAutocomplte} handleClick={handleAutocomplteSelectTo} options={options} selected={[transfer.dataPost]} className="rbt-input-no-border border input-blue bg-white" />
          </Col>
          <Col lg="6" xl="3" className="my-2">
            <DateTimeInput range={rangeDate} onDateChange={onDateChange} id="date-transfer" leftLabel={t("depart")} rightLabel={t("return")} checkin={dateFr(transfer.dataPost.DateFrom, 'datetime2')} checkout={dateFr(transfer.dataPost.DateReturn, 'datetime2')} />
          </Col>
          <Col md="6" lg="3" xl="2" className="my-2">
            <Form role="button" onClick={handleShowGuests} as="div" className="bg-white input-blue text-secondary border d-flex justify-content-center align-items-center" style={{ height: "1.8cm" }}>
              {AdultsCount} {parseInt(AdultsCount) === 1 ? t("ADT") : t("adults")} | {ChildsCount} {parseInt(ChildsCount) === 1 ? t("CHD") : t("childs")}
            </Form>
          </Col>
          <Col md="6" lg="3" xl="1" className="my-2">
            <Form as="div" className="d-flex">
              <button className="btn-orange p-3 w-100 text-dark" style={{ height: "1.8cm" }}>
                {loading ? <Form as="span" className="spinner-border spinner-border-sm m-1"></Form> : <Search size={20} />}
              </button>
            </Form>
          </Col>

        </Row>
        <Guests showGuests={showGuests} handleCloseGuests={handleCloseGuests} handleSetChildAge={handleSetChildAge} handleShowGuests={handleShowGuests} AdultsCount={AdultsCount} handlesetAdultsCount={handlesetAdultsCount} ChildsCount={ChildsCount} handlesetChildsCount={handlesetChildsCount} GuestsCount={GuestsCount} handleNationalitySelect={handleNationalitySelect} ChildAge={transfer.dataPost.ChildAge} />
      </form>

    </Form>
  );
};

export default Availability;

const setDataPost = (value) => {
  return {
    type: "data_post_transfer",
    payload: value,
  };
};

const setAvailability = (value) => {
  return {
    type: "set_availability_transfer",
    payload: value,
  };
};
